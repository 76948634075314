import React, { useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

//antd imports
import { FolderOutlined,FileTextOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu} from 'antd';

const { Sider } = Layout;

const AideDocsMenu = (props, ref) => {
  const {dark_mode, ...otherProps } = props;
  const [json_menu_items, setJsonMenuItems] = useState([]);
  const [json_doc_types: MenuProps['items'], setJsonDocTypes] = useState([]);
  const navigate = useNavigate();  


    function findArrayElementById(array, id) {
      return array.find((element) => {
        return element.id === id;
      })
    }

   useEffect(() => {
      let doc_types  = [];
      const domain_root = process.env.REACT_APP_HTTPS_DB_DOMAIN_ROOT;
      const url = `${domain_root}/index.php/db/getDocTitles?lang=EN&typename=%`;
      const url_doc_types = `${domain_root}/index.php/db/getDocTypes?lang=EN`;
    
      const handleFetchData = () => {
        return fetch(url,{
          method: 'GET',
          credentials: 'include',
          })        
          .then(response => response.json())
       }; 
 
      const handleFetchDocTypes = () => {
        return fetch(url_doc_types,{
          method: 'GET',
          credentials: 'include',
          })        
          .then(response => response.json())
        }; 

      handleFetchDocTypes()
      .then(response => {
        doc_types = response.map((el, idx) => {
              el.icon = React.createElement(FolderOutlined);//DeploymentUnitOutlined
              el.children = [];
              return { 
                key: el.name, 
                id: el.name, 
                label: el.title,
                icon : el.icon,
                children : el.children
              };
          });
          
          return doc_types;
        })
      .then(handleFetchData)
      .then(response => {
              const doc_items = response.map((el, idx) => {
                    el.icon = React.createElement(FileTextOutlined);
                    return { 
                      key: el.slug, 
                      label: el.title,
                      icon : el.icon,
                      doc_type : el.doc_type,
                    };
              });
              doc_items.forEach( 
                function(doc_item)
                {
                  var main_menu_item = findArrayElementById(doc_types,doc_item.doc_type);
                  if(main_menu_item)
                    main_menu_item.children.push(doc_item);
                    
                }
              )
              setJsonDocTypes(doc_types);
              setJsonMenuItems(doc_items);
            })
    }, [useLocation().pathname]);
    

  if (!json_doc_types) 
    return <div>Fetching doc types ...</div>
  if (!json_menu_items) 
    return <div>Fetching menu ...</div>

  


  return (
        <Sider width={300} collapsedWidth={50} className={'sideMenuProfile'} collapsed={false}>
  
        {/* <pre>{JSON.stringify(json_doc_types, null, 2)}</pre> */}

        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          items={json_doc_types}
          onClick={({ keyPath }) => navigate(`/aide-docs/${keyPath[0]}`)}
        />


        </Sider>
        
);
};

export default AideDocsMenu;



