


const Global = () => { }



Global.current_theme = 'light';
Global.darkmode = false;



export default Global;