import React, { useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';

import { lazy, Suspense } from 'react';

//markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

//highlighter
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

//highlighter: languages
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';

//highlighter: stylesheets
import ocean from 'react-syntax-highlighter/dist/esm/styles/hljs/ocean';

//markdown stylesheeting
//https://www.npmjs.com/package/github-markdown-css
//https://sindresorhus.com/github-markdown-css/

//import github_markdown_css from "github-markdown-css/github-markdown-light.css"
//import github_markdown_dark_css from "github-markdown-css/github-markdown-dark.css"

//global setting (usersetting, global variables, ...)
import Global from '../../global/global'
import User from '../../global/user'

const AideEduBaseContent = ({type,slug}) => {
    const [article, setArticle] = useState([]);
    const domain_root = "https://db.aidetool.org";
    const public_files_root = "https://aidetool.org";
    const url = `${domain_root}/index.php/db/get?slug=${slug}&lang=EN`;
    
    const github_markdown_css = (Global.current_theme=='light') ? import ('github-markdown-css/github-markdown-light.css') : import ('github-markdown-css/github-markdown-dark.css');

    useEffect(() => {
        const handleFetchData = () => {
            if(slug !== "")
                return fetch(url)
                .then(response => response.json())
            }; 
        handleFetchData().then(data => {setArticle(data)});
      }, [useLocation().pathname]);

      if(!slug) return <div>Empty slug: {slug}...</div>
      if (!article) return <div>Fetching {slug} from {url}...</div>
      if (!article.content) return <div>Fetching {slug}...</div>
      if (article.content && article.content==="") return <div>No edubase content for {slug}...</div>
      
      SyntaxHighlighter.registerLanguage('javascript', js);
      SyntaxHighlighter.registerLanguage('bash', bash);

      return (
        <div className='markdown-body'>
          {/* <div>Fetching {slug} from {url}</div><br/> */}
          
          <ReactMarkdown
                class={github_markdown_css}
                className='markdown-body'
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]} 
                components={{

                        code(props) {
                            const {children, className, node, ...rest} = props
                            const match = /language-(\w+)/.exec(className || '')
                            return match ? (
                                <SyntaxHighlighter
                                    {...rest}
                                    PreTag="div"
                                    children={String(children).replace(/\n$/, '')}
                                    language={match[1]}
                                    showLineNumbers={false}
                                    wrapLongLines={true}
                                    wrapLines={true}
                                    style={ocean}
                                    customStyle={ocean}
                                />
                            ) : (
                                <code {...rest} className={className}>
                                {children}
                                </code>
                            )
                            },
                        img(props){
                            return(
                                <img
                                src={`${public_files_root}/images/${props.src}`}
                                alt={props.alt}
                                width="600"
                                height="400"
                            />                                
                            )
                        }

                        }}
            >
                {article.content}
                {/* {article.content.replace(/\n/gi, "&nbsp; \n")} */}
            </ReactMarkdown> 

        </div>
      );
    }

export default AideEduBaseContent;

