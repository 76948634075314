
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";


import App from './App.tsx';

import './i18n';


createRoot(document.getElementById('container')).render(
    <Router>
        <App />

    </Router>
);
