import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(Backend) // passes i18n down to react-i18next
//  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance  
  .init({
    fallbackLng: 'en',
//    debug : true,
    backend:{
        loadPath: ('https://db.aidetool.org/index.php/user/langfile?lang={{lng}}')
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18next;