

const User = () => { }

User.fetchAccountData = async() => {
    const url = `${process.env.REACT_APP_HTTPS_DB_DOMAIN_ROOT}/index.php/user/getProfile`;
    await fetch(url,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'credentials' : 'include',
            },
        })
        .then(data => {
            return data.json();
        })
        .then(data => {
            if(data.result == "SUCCESS")
            {
                User.usersettings = data;
                //Global.dark_mode = User.usersettings.dark_mode;
            } 
        })
        .catch((error) => console.log(error));
};

User.usersettings = User.fetchAccountData();

export default User;